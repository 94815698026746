import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const ExamplesOfDigitalLottery = ({ location }) => {
  const title =
    "来店集客イベントに！デジタル抽選会の導入例＆注意点を解説します【コスト削減＆感染症対策】"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="来店集客イベントに！デジタル抽選会の導入例＆注意点を解説します"
          pagedesc="非接触型のイベントをコスト削減しながら実施できる「オンライン抽選会」について解説。ガラポン抽選会を効率化したい、イベント活性化を目指す担当者必見の内容です。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-digital-lottery.jpg"
          pagepath="/blog/examples-of-digital-lottery"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              来店集客イベントに！デジタル抽選会の導入例＆注意点を解説します【コスト削減＆感染症対策】
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月21日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="テーブルの上のQRコードと花瓶"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/examples-of-digital-lottery.jpg"
                className="w-full"
              />
            </figure>
            <h2>オンライン消費が増加中、抽選会もデジタル化が進む</h2>
            <p>
              <a
                href="https://www.soumu.go.jp/johotsusintokei/whitepaper/ja/r03/pdf/n2100000.pdf"
                target="_blank"
                rel="noreferrer"
              >
                総務省のデータ
              </a>
              によると、2人以上の世帯の約半分以上がインターネットショッピングをする時代となりました。QRコード決済やオンラインデリバリーサービスの浸透など、身近なところでのデジタル化は加速するばかりです。Webを使ったシステムに移行することで、消費者だけでなく事業者にも大きなメリットが生まれています。
            </p>
            <h2>
              Web抽選システムで、参加者も開催者もうれしい販促キャンペーンを
            </h2>
            <p>
              その中で、抽選会や福引イベントのデジタル化も増加傾向にあります。従来は、催事スペースを確保し物理的な集客をする抽選会が、販売促進の定番施策として多く実施されていました。しかし、デジタルWeb抽選システムの導入で、コストや業務負担を抑えながらも、参加者に喜ばれるキャンペーンの開催が可能となりました。
            </p>
            <p>
              この記事では、QRコードを使ったデジタル抽選会開催の詳しい機能やメリット、イベント導入案を解説しています。
            </p>
            <p>この記事は次のような人におすすめです！</p>
            <ul className="blog__border-list">
              <li>
                毎年やっている抽選会の準備＆開催コストや業務負担が大きすぎて困っている
              </li>
              <li>店舗集客イベントを計画しているが、感染症対策も気になる</li>
              <li>来場者プレゼントの配布をデジタル化してコストを抑えたい</li>
            </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    QRスマホくじシステムでQR生成〜自動抽選〜景品配布まで一気に効率化
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    抽選会のデジタル化で、業務効率化・感染症対策・シームレスなデジタル活用へ
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id3" className="toc__link">
                        1. 業務効率化、コスト削減
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        2. 密の回避で感染症対策
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        3.
                        オンラインとのスムーズな連携、SNS販促⇆実店舗⇆自社サイトの導線強化
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    QRコードスマホくじシステムを集客イベントで活用・事例3選
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        1. 商店街でOR抽選券を配ってデジタル抽選会
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        2.
                        住宅展示場でQR入場券を配ってオンラインで来場者全員プレゼント
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        3.
                        ヘアサロンでLINE登録で実店舗利用のクーポンをプレゼント
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    QRコードスマホくじシステム導入には注意点も…
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        セキュリティ対策
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        印刷〜抽選まで一括依頼できるか
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        SNSマーケティングの知見はあるか
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id14" className="toc__link">
                    PARKLoTのQRスマホくじ機能なら一気通貫
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">
                QRスマホくじシステムでQR生成〜自動抽選〜景品配布まで一気に効率化
              </h2>
              <p>
                まずはQRスマホくじシステムがどういう仕組みか、基本的なイベント開催フローを説明します。
              </p>
              <ol className="blog__border-list">
                <li>店頭買上げ客に、QRコード付きの抽選券を渡します</li>
                <li>
                  買上げ客は、QRコードをスマホでスキャンし、抽選サイトへアクセスします
                  <p>
                    →
                    Web抽選システムが自動抽選を開始。その場で抽選結果が分かります！
                  </p>
                </li>
                <li>当選景品の配布も、オンライン上でスムーズに完了</li>
              </ol>
            </div>
            <div>
              <h2 id="id2">
                抽選会のデジタル化で、業務効率化・感染症対策・シームレスなデジタル活用へ
              </h2>
              <p>
                抽選会をデジタル開催することで、どういったメリットがあるのでしょうか？
              </p>
            </div>
            <div>
              <h3 id="id3">1. 業務効率化、コスト削減</h3>
              <p>
                従来の抽選会で最も開催者の負担になっていたのが、準備物の業務的コストと実施の人的コストです。準備段階では、催事スペースの確保や設営作業、ガラポン抽選機のレンタルなど多様なタスクが発生します。さらに実施する段階でも、担当スタッフの配置やお客様対応、当選景品の引き換えなど、かなりの人的コストがかかってしまいます。
              </p>
              <p>
                QRスマホくじシステムでデジタル化をすれば、そんなコストが大幅に削減できます。準備や片付け作業が不要になり、当日のスタッフ確保・配置に悩むこともありません！
              </p>
              <div className="blog__border-highlight">
                <p>
                  <span className="emoji">&#128483;</span>
                  　PARKLoTマーケ担当のひとこと
                </p>
                <p>「営業時間後の設営作業、本当におつかれさまです…」</p>
              </div>
            </div>
            <div>
              <h3 id="id4">2. 密の回避で感染症対策</h3>
              <p>
                「福引抽選会」といえば、ずらっと並んだ参加待ちの行列をイメージされる方も多いことでしょう。しかし、感染症の懸念のある現在は、できるだけ参加者の1カ所での密集を避けたいものです。スマホ参加の抽選会であれば、非接触型のイベントが実施できます。
              </p>
              <p>
                いつでも、どこでも参加できるのも、デジタル抽選会ならではの利点です。店頭で抽選券をもらったものの、「面倒くさい」からと抽選会場へ行かない層も一定数存在します。そういった層にも抽選へ参加してもらいやすく、キャンペーンの参加率向上が見込めるシステムだともいえるでしょう。
              </p>
            </div>
            <div>
              <h3 id="id5">
                3.
                オンラインとのスムーズな連携、SNS販促⇆実店舗⇆自社サイトの導線強化
              </h3>
              <p>
                オムニチャネル戦略が重要と言われる今、抽選会を単独のイベントにしてしまうのは、非常にもったいないことです。オンライン抽選にすれば、スマホで抽選サイトへアクセスした参加者を、自社ECサイトや他のSNSへ誘導することもできます。抽選イベントの開催をSNSで事前告知して実施を盛り上げ、開催後SNS上の口コミ活用UGCでロイヤル顧客化を図るなど、総合的な販売促進が可能です。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a href="/blog/what-is-ugc" target="_blank" rel="noreferrer">
                    UGCとは？活用のメリットや上手な活用方法・注意点をわかりやすく解説！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id6">
                QRコードスマホくじシステムを集客イベントで活用・事例3選
              </h2>
              <p>
                QRスマホくじの仕組みとメリットがわかったところで、実際どういった活用ができるかを紹介します。
              </p>
            </div>
            <div>
              <h3 id="id7">1. 商店街でOR抽選券を配ってデジタル抽選会</h3>
              <ul>
                <li>
                  活用おすすめ
                  <p>百貨店、ファッションビル、商業施設、スーパー</p>
                </li>
                <li>
                  企画内容
                  <p>
                    店頭買上げ客に、QRコード（シリアルナンバー含む）付きの抽選券を配布
                  </p>
                  <p>
                    →買上げ客はスマホでQRコードを読み込み、抽選サイトで応募、すぐに結果がわかる
                  </p>
                  <p>
                    →結果確認画面にECサイトURLやSNSアカウントURLを表示させ、遷移を促す
                  </p>
                </li>
                <li>
                  景品設定
                  <p>１等：温泉ペア招待券（当選画面から住所氏名を入力）</p>
                  <p>２等：店頭で使える50％OFFデジタルクーポン</p>
                  <p>ハズレなし：店頭で使える5％OFFデジタルクーポン</p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id8">
                2. 住宅展示場でQR入場券を配ってオンラインで来場者全員プレゼント
              </h3>
              <ul>
                <li>
                  活用おすすめ
                  <p>カーディーラー、学校オープンキャンパス、セミナー</p>
                </li>
                <li>
                  企画内容
                  <p>
                    イベント来場者全員に、QRコード（シリアルナンバー含む）付きの抽選券を配布
                  </p>
                  <p>
                    →来場者はスマホでQRコードを読み込み、デジタルギフト券500円分をもらう
                  </p>
                </li>
                <li>
                  景品設定
                  <p>
                    全員：デジタルギフト券（Amazonギフト券やQUOカードPayなど）
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <h3 id="id9">
                3. ヘアサロンでLINE登録で実店舗利用のクーポンをプレゼント
              </h3>
              <ul>
                <li>
                  活用おすすめ
                  <p>エステサロン、タクシー会社、美容クリニック、整体</p>
                </li>
                <li>
                  企画内容
                  <p>LINE友だち登録した人に、LINE上でQRコードとURLを送付</p>
                  <p>
                    →参加者は遷移先サイトで抽選に参加。実店舗で利用できるデジタルクーポンをもらう
                  </p>
                </li>
                <li>
                  景品設定
                  <p>１等：3,000円OFFクーポン</p>
                  <p>ハズレなし：500円OFFクーポン</p>
                </li>
              </ul>
            </div>
            <div>
              <h2 id="id10">QRコードスマホくじシステム導入には注意点も…</h2>
            </div>
            <div>
              <h3 id="id11">セキュリティ対策</h3>
              <p>
                セキュリティ対策も万全に行えるか、チェックしましょう。データの暗号化、不正アクセス対策などは、安心して参加してもらうために必須です。
              </p>
            </div>
            <div>
              <h3 id="id12">印刷〜抽選まで一括依頼できるか</h3>
              <p>
                スムーズな企画進行のために、できるだけ印刷代行もできるプランをおすすめします。一括依頼できる会社なら、イベント用イラストの細かな修正や応募規約の変更などにも、スケジュール間近まで対応してもらえます。抽選用のランディングページの作成まで依頼できれば理想的です。
              </p>
            </div>
            <div>
              <h3 id="id13">SNSマーケティングの知見はあるか</h3>
              <p>
                自動くじシステムだけではなく、SNSマーケティング全般についての知識がある会社へ依頼しましょう。イベント告知〜開催後のSNS運用についてのアドバイスももらえ、販促効果がアップします。
              </p>
            </div>
            <div>
              <h2 id="id14">PARKLoTのQRスマホくじ機能なら一気通貫</h2>
              <p>
                PARKLoTでは、セキュリティも万全なWeb抽選会システムを提供しております。Twitterで完結するインスタントウィンキャンペーン等を多数開催してきた実績から、Webマーケティング知識も豊富です。印刷からランディングページ作成、抽選システムまで、一括でご依頼ください。
              </p>
              <p>
                イベントの特色や開催の目的に合わせた抽選会を、全力でサポートいたします！
              </p>
              <div className="blog__border-highlight">
                <p>
                  QRスマホくじシステムの企画・導入について、無料でご相談いただけます！
                </p>
                <p>
                  ▶︎
                  <a
                    href="https://hub.park-lot.com/free-consulting"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://hub.park-lot.com/free-consulting
                  </a>
                </p>
              </div>
              <p>※QRコードは(株)デンソーウェーブの登録商標です。</p>
            </div>
            <div className="blog__sns-container--foot">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/2022-fan-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【2022年版】Twitter
                    企業アカウント運用のコツと注意点（ファンマーケティング）
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a href="/blog/twitter-dm" target="_blank" rel="noreferrer">
                    TwitterのDMを一斉送信するツールを使って、キャンペーンの実施にかかる手間を削減しましょう
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-restaurant"
                    target="_blank"
                    rel="noreferrer"
                  >
                    飲食店にはTwitterのクーポン配布キャンペーンがおすすめ！認知度&amp;来店客数アップに最適な理由を解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-get-friends-for-line"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LINE公式アカウントの友だちを増やす方法はこれ！「Twitter×LINE
                    ハイブリッドキャンペーン」
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default ExamplesOfDigitalLottery
